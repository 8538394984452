.user-profile-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f9f9f9;
}

.profile-heading {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.profile-list {
  list-style-type: none;
  padding: 0;
}

.profile-item {
  margin-bottom: 12px;
  font-size: 18px;
  color: #555;
  border-bottom: 1px solid #ddd; /* Add a subtle border between items */
  padding-bottom: 6px;
}

/* Highlight the odd items for better readability */
.profile-item:nth-child(odd) {
  background-color: #f5f5f5;
}
